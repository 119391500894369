import React from 'react'
import AllRoutes from './Routes/AllRoutes'


function App() {
  return (
   <>
 
     <AllRoutes/>

   </>
  )
}

export default App